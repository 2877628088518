export const categories = [
  {
    label: 'Reels',
    subOptions: [
      {
        label: 'Spinning Reels',
        id: 'reels>spinning_reels',
      },
      {
        label: 'Baitcast Reels',
        id: 'reels>baitcast_reels',
      },
      {
        label: 'Overhead Reels',
        id: 'reels>overhead_reels',
      },
      {
        label: 'Fly Reels',
        id: 'reels>fly_reels',
      },
      {
        label: 'Electric Reels',
        id: 'reels>electric_reels',
      },
    ],
    id: 'reels',
  },
  {
    label: 'Rods',
    subOptions: [
      {
        label: 'Spinning Rods',
        id: 'rods>spinning_rods',
      },
      {
        label: 'Baitcast Rods',
        id: 'rods>baitcast_rods',
      },
      {
        label: 'Overhead Rods',
        id: 'rods>overhead_rods',
      },
      {
        label: 'Fly Rods',
        id: 'rods>fly_rods',
      },
      {
        label: 'Electric',
        id: 'rods>electric_rods',
      },
      {
        label: 'Jigging Rods',
        id: 'rods>jigging_rods',
      },
      { label: 'Surf Rods', id: 'rods>surf_rods' },
    ],
    id: 'rods',
  },
  {
    label: 'Lures',
    subOptions: [
      {
        label: 'Soft Plastics',
        id: 'lures>soft_plastics',
      },
      {
        label: 'Hard Body Lures',
        id: 'lures>hard_body_lures',
      },
      {
        label: 'Metal Lures',
        id: 'lures>metal_lures',
      },
      {
        label: 'Jigs',
        id: 'lures>jigs',
      },
      {
        label: 'Vibes',
        id: 'lures>vibes',
      },
      {
        label: 'Trolling Lures',
        id: 'lures>trolling_lures',
      },
      {
        label: 'Squid Jigs',
        id: 'lures>squid_jigs',
      },
      {
        label: 'Surface Lures',
        id: 'lures>surface_lures',
      },
    ],
    id: 'lures',
  },
  {
    label: 'Combos',
    subOptions: [
      {
        label: 'Spinning Combos',
        id: 'combos>spinning_combos',
      },
      {
        label: 'Baitcast Combos',
        id: 'combos>baitcast_combos',
      },
      {
        label: 'Overhead Combos',
        id: 'combos>overhead_combos',
      },
      {
        label: 'Fly Combos',
        id: 'combos>fly_combos',
      },
      {
        label: 'Electric Combos',
        id: 'combos>electric_combos',
      },
    ],
    id: 'combos',
  },
  {
    label: 'Other',
    subOptions: [
      {
        label: 'Tools',
        id: 'other>tools',
      },
      {
        label: 'Line',
        id: 'other>line',
      },
      {
        label: 'Hooks, Sinkers, Swivels',
        id: 'other>hooks_sinkers_swivels',
      },
      {
        label: 'Electronics',
        id: 'other>electronics',
      },
      {
        label: 'Miscellaneous',
        id: 'other>misc',
      },
    ],
    id: 'combos',
  },
];

export const navigationMenuItems = [
  {
    title: 'Reels',
    categories: [],
    column1: [
      { label: 'Spinning Reels', link: '/s?pub_categoryArray=reels>spinning_reels' },
      { label: 'Baitcast Reels', link: '/s?pub_categoryArray=reels>baitcast_reels' },
      { label: 'Overhead Reels', link: '/s?pub_categoryArray=reels>overhead_reels' },
      { label: 'Fly Reels', link: '/s?pub_categoryArray=reels>fly_reels' },
      { label: 'Electric Reels', link: '/s?pub_categoryArray=reels>electric_reels' },
      { label: 'All Reels', link: '/s?pub_categoryArray=reels' },
    ],
  },
  {
    title: 'Rods',
    categories: [],
    column1: [
      { label: 'Spinning Rods', link: '/s?pub_categoryArray=rods>spinning_rods' },
      { label: 'Baitcast Rods', link: '/s?pub_categoryArray=rods>baitcast_rods' },
      { label: 'Overhead Rods', link: '/s?pub_categoryArray=rods>overhead_rods' },
      { label: 'Fly Rods', link: '/s?pub_categoryArray=rods>fly_rods' },
      { label: 'Electric', link: '/s?pub_categoryArray=rods>electric_rods' },
      { label: 'Jigging Rods', link: '/s?pub_categoryArray=rods>jigging_rods' },
      { label: 'Surf Rods', link: '/s?pub_categoryArray=rods>surf_rods' },
      { label: 'All Rods', link: '/s?pub_categoryArray=rods' },
    ],
  },
  {
    title: 'Lures',
    categories: [],
    column1: [
      { label: 'Soft Plastics', link: '/s?pub_categoryArray=lures>soft_plastics' },
      { label: 'Hard Body Lures', link: '/s?pub_categoryArray=lures>hard_body_lures' },
      { label: 'Metal Lures', link: '/s?pub_categoryArray=lures>metal_lures' },
      { label: 'Jigs', link: '/s?pub_categoryArray=lures>jigs' },
      { label: 'Vibes', link: '/s?pub_categoryArray=lures>vibes' },
      { label: 'Trolling Lures', link: '/s?pub_categoryArray=lures>trolling_lures' },
      { label: 'Squid Jigs', link: '/s?pub_categoryArray=lures>squid_jigs' },
      { label: 'Surface Lures', link: '/s?pub_categoryArray=lures>surface_lures' },
      { label: 'All Lures', link: '/s?pub_categoryArray=lures' },
    ],
  },
  {
    title: 'Combos',
    categories: [],
    column1: [
      { label: 'Spinning Combos', link: '/s?pub_categoryArray=combos>spinning_combos' },
      { label: 'Baitcast Combos', link: '/s?pub_categoryArray=combos>baitcast_combos' },
      { label: 'Overhead Combos', link: '/s?pub_categoryArray=combos>overhead_combos' },
      { label: 'Fly Combos', link: '/s?pub_categoryArray=combos>fly_combos' },
      { label: 'Electric Combos', link: '/s?pub_categoryArray=combos>electric_combos' },
      { label: 'All Combos', link: '/s?pub_categoryArray=combos' },
    ],
  },
  {
    title: 'Other',
    column1: [
      { label: 'Tools', link: '/s?pub_categoryArray=other>tools' },
      { label: 'Line', link: '/s?pub_categoryArray=other>line' },
      { label: 'Electronics', link: '/s?pub_categoryArray=other>electronics' },
      {
        label: 'Hooks, Sinkers, Swivels',
        link: '/s?pub_categoryArray=other>hooks_sinkers_swivels',
      },
      { label: 'Miscellaneous', link: '/s?pub_categoryArray=other>misc' },
      { label: 'View all', link: '/s?pub_categoryArray=other' },
    ],
  },
];

export const findCategoryById = id => {
  const stack = [...categories];
  while (stack.length > 0) {
    const currentOption = stack.pop();

    const cleanedId = id.replace(/＞/g, '>');
    if (currentOption.id === cleanedId) {
      return currentOption;
    }
    if (currentOption.subOptions) {
      stack.push(...currentOption.subOptions);
    }
  }
  return null; // ID not found
};
