import React, { useState } from 'react';
import './MegaMenu.css'; // Import CSS file for styling
import classNames from 'classnames';

import css from './MegaMenu.module.css';
import { navigationMenuItems } from '../../../../config/configCategory';

const MegaMenu = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(0);

  const menuItems = navigationMenuItems;

  const handleMenuItemHover = index => {
    // if (!isTouchDevice) {
    setActiveMenu(index);
    setActiveSubMenuItem(0);
    // }
  };

  const handleSubMenuHover = index => {
    setActiveSubMenuItem(index);
  };

  const handleMenuItemClick = index => {
    setActiveMenu(index);
  };

  const handleSubMenuItemClick = index => {
    if (activeSubMenuItem === index) {
      setActiveSubMenuItem(1);
    } else {
      setActiveSubMenuItem(index);
    }
  };

  return (
    <div className="mega-menu">
      {menuItems.map((menuItem, index) => (
        <div
          key={index}
          className="menu-item"
          onMouseEnter={() => handleMenuItemHover(index)}
          onMouseLeave={() => handleMenuItemHover(null)}
          onClick={() => handleMenuItemClick(index)}
        >
          <div className={classNames('menu-title', { active: activeMenu === index })}>
            {menuItem.title}
          </div>

          {activeMenu === index && (
            <div className="submenu">
              <div className="submenu-container">
                <div className="submenu-row">
                  <ul>
                    {menuItem.column1.map((link, linkIndex) => (
                      <li
                        key={linkIndex}
                        onClick={() => {
                          window.location.href = link.link;
                        }}
                      >
                        {link.label}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className={css.megamenuseperator}></div> */}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export { MegaMenu };
